.section-text-container {
  h2,
  h3,
  h4 {
    &:first-child {
      padding-top: 0;
    }
  }
  span:last-of-type:empty {
    display: none;
  }

  p:empty {
    display: none;
  }

  a:last-child,
  span:last-child,
  p:last-child {
    margin-bottom: 0;
  }
}
.quote-text-container {
  h2,
  h3,
  h4 {
    &:first-child {
      padding-top: 0;
    }
  }
  span:last-of-type:empty {
    display: none;
  }

  p:empty {
    display: none;
  }

  a:last-child,
  span:last-child,
  p:last-child {
    margin-bottom: 0 !important;
  }
}
