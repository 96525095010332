@page {
  size: auto;
  margin: 25mm 25mm 25mm 25mm;
}

/* prettier-ignore */
@page:first {
  margin: 0mm 25mm 25mm 25mm;
}

html,
body {
  min-height: 100vh;
  margin: 0;
}
