:root {
  --titleColor: #1d1d1d;
  --fontColor: #1d1d1d;
  --buttonColor: #f3c84f;
  --linkColor: #4d9373;
  --buttonFontColor: #1d1d1d;
  --buttonColorHover: #f3c84f;
  --fontHeading: "__Walsheim_7a05e0", "__Walsheim_Fallback_7a05e0",
    "__Poppins_1869ca", "__Poppins_Fallback_1869ca";
  --fontNormal: "__Poppins_1869ca", "__Poppins_Fallback_1869ca";
  --buttonBoxShadowHover: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);

  --error-color: #f2545b;
}

.hubspotFormContainer {
  font-family: var(--fontNormal);
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.01em;
  margin-bottom: 16px;
  white-space: pre-wrap;
  word-break: break-word;
}

.hubspotFormContainer .form-columns-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: unset;
}
.hubspotFormContainer .form-columns-2 + .form-columns-1 {
  max-width: unset !important;
}

.hubspotFormContainer fieldset.form-columns-0 {
  width: 100%;
  max-width: unset !important;
}
.hubspotFormContainer fieldset.form-columns-2 {
  width: 100%;
}
.hubspotFormContainer fieldset.form-columns-1 {
  width: 100%;
  max-width: unset !important;
}

.hubspotFormContainer strong,
.hubspotFormContainer h1,
.hubspotFormContainer h2,
.hubspotFormContainer h3,
.hubspotFormContainer h4 {
  margin-bottom: 32px !important;
  text-align: center;
  margin: 0;
  font-family: var(--fontHeading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  color: var(--fontColor);
  font-size: 34px;
  display: block;
  color: var(--titleColor) !important;
  line-height: 1.2 !important;
}

.hubspotFormContainer p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.hubspotFormContainer h1 {
  font-size: 48px !important;
}
.hubspotFormContainer h2 {
  font-size: 34px !important;
}
.hubspotFormContainer h3 {
  font-size: 28px !important;
}
.hubspotFormContainer h4 {
  font-size: 20px !important;
}
.hubspotFormContainer .bodySmall {
  font-size: 14px;
}
.hubspotFormContainer .bodyRegular {
  font-size: 16px;
}
.hubspotFormContainer .bodyLarge {
  font-size: 16px;
}

.hubspotFormContainer .hs-main-font-element {
  text-align: left;
  width: fit-content;
  margin-bottom: 16px;
}
.hubspotFormContainer .hs-main-font-element h2 {
  text-align: left;
}
@media (min-width: 600px) {
  .hubspotFormContainer .hs-main-font-element h2 {
    text-align: center;
  }
  .hubspotFormContainer .hs-main-font-element {
    justify-content: center;
    width: 100%;
  }
  .hubspotFormContainer h2 {
    font-size: 38px !important;
  }
  .hubspotFormContainer .form-columns-2 {
    flex-direction: row;
  }

  .hubspotFormContainer .form-columns-2 .hs-form-field,
  .hubspotFormContainer .form-columns-3 .hs-form-field,
  .hubspotFormContainer .form-columns-2 .hs-form-field .hs-input,
  .hubspotFormContainer .form-columns-3 .hs-form-field .hs-input {
    float: none !important;
  }
}
@media (max-width: 1200px) {
  .MuiGrid-grid-md-6 .hubspotFormContainer .form-columns-2 {
    flex-direction: column;
  }
}

.hubspotFormContainer form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.hubspotFormContainer .legal-consent-container .hs-dependent-field {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.hubspotFormContainer .legal-consent-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.hubspotFormContainer .legal-consent-container,
.hubspotFormContainer .legal-consent-container input,
.hubspotFormContainer .legal-consent-container p,
.hubspotFormContainer .legal-consent-container span,
.hubspotFormContainer .hs-error-msg {
  font-size: 12px !important;
  color: var(--fontColor);
  font-family: var(--fontNormal) !important;
}

.hubspotFormContainer
  .legal-consent-container
  .hs-form-booleancheckbox-display
  > span {
  margin-left: 8px;
}

ul.hs-error-msgs {
  margin: 0px;
  margin-left: 0px;
  position: absolute;
  bottom: -21px;
}
.legal-consent-container ul.hs-error-msgs {
  margin-left: 10px;
}
.hubspotFormContainer .legal-consent-container ul.hs-error-msgs {
  position: relative;
  top: -13px;
  text-align: left;
  width: 100%;
}
.hubspotFormContainer .legal-consent-container .hs-error-msgs li {
  position: absolute;
  margin-left: 0px;
}
.hs-error-msgs li {
  width: 100%;
  margin-top: 2px;
  left: 17px;
}
.hubspotFormContainer .actions {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.hubspotFormContainer .input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 0px !important;
}
.hubspotFormContainer .field.hs-form-field {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  flex-direction: column;
  align-items: center;
  min-width: 220px;
  width: calc(100% - 48px) !important;
  position: relative;
}

.hubspotFormContainer .field.hs-form-field label {
  margin-left: 16px;
}
.hubspotFormContainer .legal-consent-container .field.hs-form-field label {
  margin-left: 0px;
  margin-right: 0px;
}

.hubspotFormContainer .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field,
.hubspotFormContainer .hs_firstname.hs-fieldtype-text.field.hs-form-field {
  align-items: flex-start;
}

.hubspotFormContainer form input[type="text"],
.hubspotFormContainer form input[type="search"],
.hubspotFormContainer form input[type="email"],
.hubspotFormContainer form input[type="password"],
.hubspotFormContainer form input[type="tel"],
.hubspotFormContainer form input[type="number"],
.hubspotFormContainer form input[type="file"],
.hubspotFormContainer form select,
.hubspotFormContainer form textarea input[type="text"],
.hubspotFormContainer input[type="email"] {
  height: 36px;
  color: var(--fontColor) !important;
  border-radius: 100px !important;
  padding: 0.25em 1em 0.25em 1em !important;
  border: 0.1px solid rgba(150, 150, 150, 0.5);
  width: 100%;
}

.hubspotFormContainer input::placeholder {
  color: var(--fontColor) !important;
}
.hubspotFormContainer label.hs-form-booleancheckbox-display {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.hubspotFormContainer .hs-button.primary.large {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: var(--buttonColor);
  font-family: var(--fontNormal);
  border-radius: 100px;
  box-shadow: none;
  padding: 10px 24px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: -0.01em;
  border-color: var(--buttonFontColor);
  color: var(--buttonFontColor);
}
.hubspotFormContainer .hs-button.primary.large:hover {
  background-color: var(--buttonColorHover);
  box-shadow: var(--buttonBoxShadowHover);
}
.hubspotFormContainer li::marker {
  display: none;
}
.hubspotFormContainer ul {
  list-style-type: none;
  padding: 0;
}

.hubspotFormContainer .hs-error-msg {
  color: var(--error-color);
}

input[type="checkbox"] {
  width: 35px;
  height: 15px;
}

.hubspotFormContainer a {
  color: var(--linkColor) !important;
}

.docfleck .hubspotFormContainer input[type="text"],
.docfleck .hubspotFormContainer input[type="email"] {
  border-radius: 24px !important;
  border: 3px solid var(--green-01, #97c4b0);
  background: rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  height: 68px;
  flex-shrink: 0;
  padding-left: 0px;
  padding: 0 !important;
  padding-left: 32px !important;
  font-size: 14px;
  color: #4a4a4a !important;
}
.docfleck .hubspotFormContainer input[type="text"]:focus,
.docfleck .hubspotFormContainer input[type="email"]:focus {
  background-color: rgba(255, 255, 255, 0.8) !important;
  outline: none;
}
.docfleck .hubspotFormContainer input[type="text"]::placeholder,
.docfleck .hubspotFormContainer input[type="email"]::placeholder {
  color: #97c4b0 !important;

  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
}

.docfleck .hubspotFormContainer .field.hs-form-field {
  margin-top: 16px;
}
.docfleck .hubspotFormContainer .field.hs-form-field:first-child {
  margin-top: 0px;
}
.docfleck .hubspotFormContainer .field.hs-form-field:last-child {
  margin-top: 16px;
}
.docfleck .hs_submit {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.docfleck .hubspotFormContainer ul {
  margin-bottom: 0;
}
.docfleck .legal-consent-container .field.hs-form-field {
  margin-bottom: 0;
}
.docfleck .hubspotFormContainer .legal-consent-container .hs-dependent-field {
  margin-bottom: 0;
}
.docfleck .hubspotFormContainer .legal-consent-container {
  margin-bottom: 32px;
}
.docfleck .hubspotFormContainer .hs-button.primary.large {
  background-color: #ff7e8f;
}
.docfleck .hubspotFormContainer .hs-button.primary.large:hover {
  background-color: #fcbac2;
}
.docfleck .hubspotFormContainer form {
  align-items: flex-start;
}
.docfleck .hubspotFormContainer .field.hs-form-field {
  margin-left: 0;
  margin-right: 0;
  width: 100% !important;
}
